import React, { useContext } from 'react';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string } from 'prop-types';
import { DynamicComponent } from '@thd-nucleus/app-render';
import { RetailMediaCarousel } from '@thd-olt-component-react/retail-media-pla-sponsored-placements';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { AUTHORIZATION_STATUS } from '../../utils';

export function SponsoredProducts({
  userAuthStatus,
  customRenderId,
  slidesPer,
  id,
}) {
  const { channel } = useContext(ExperienceContext);
  const CAROUSEL_HYDRATION_STANDARD_SCROLL_BUFFER = 750;
  const plaLocation = userAuthStatus === AUTHORIZATION_STATUS.AUTHENTICATED
    ? 'sponsoredCarouselAuthHomePage' : 'sponsoredCarouselHomePage';

  return (
    <ErrorBoundary name="sponsored-products-php">
      <DynamicComponent pageType="homepage">
        <RetailMediaCarousel
          plaLocation={plaLocation}
          slidesPer={slidesPer}
          pageContext={{
            schema:
              userAuthStatus === AUTHORIZATION_STATUS.GUEST
                ? 'hp_sponsored'
                : 'hp_sponsored_auth',
          }}
          noATCFulfillment
          hideCarouselArrows={channel === 'mobile'}
          utilizeRvData
          dynamic={{
            pageType: 'homepage'
          }}
          hydrator={{
            className: 'grid',
            scrollBuffer: CAROUSEL_HYDRATION_STANDARD_SCROLL_BUFFER
          }}
        />
      </DynamicComponent>
    </ErrorBoundary>
  );
}

SponsoredProducts.propTypes = {
  userAuthStatus: string,
  customRenderId: string,
  slidesPer: number,
  id: string,
};

SponsoredProducts.defaultProps = {
  userAuthStatus: undefined,
  customRenderId: undefined,
  slidesPer: undefined,
  id: undefined,
};
